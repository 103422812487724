// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Integrations-module__kBEpovVBYshJrLp8swu7{width:180px}.Integrations-module__U5cWP5679LrHpx7BmAcY{padding:4px 10px;width:40px}.Integrations-module__zKImn7vBrA0CIzrEvDi8{margin-bottom:24px;right:0}.Integrations-module__eRLcsHEnQjq9OiGpfgtF{height:40px}.Integrations-module__Z4XoGpuAk0n3Xf4xqtOy{margin-top:16px}.Integrations-module__FjFvzvSG8py9_wYalVEn{display:flex;flex-direction:column;width:200px;border-radius:2px}.Integrations-module__Z90vOvIKuEGLk8qcenaJ{padding:8px;display:flex;align-items:center;flex-direction:row;flex-shrink:0;white-space:nowrap;border-left:2px solid rgba(0,0,0,0);cursor:pointer;min-width:84px;display:flex;gap:8px;flex-direction:row}.Integrations-module__Z90vOvIKuEGLk8qcenaJ:hover{background:var(--cards-background)}", "",{"version":3,"sources":["webpack://./pages/integrations/Integrations.module.scss"],"names":[],"mappings":"AAAA,2CACE,WAAA,CAGF,2CACE,gBAAA,CACA,UAAA,CAGF,2CACE,kBAAA,CACA,OAAA,CAGF,2CACE,WAAA,CAGF,2CACE,eAAA,CAGF,2CACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAGF,2CACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,mCAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,iDACE,kCAAA","sourcesContent":[".newIntegrationButton {\n  width: 180px;\n}\n\n.heartbeat-badge {\n  padding: 4px 10px;\n  width: 40px;\n}\n\n.integrations-header {\n  margin-bottom: 24px;\n  right: 0;\n}\n\n.integrations-table-row {\n  height: 40px;\n}\n\n.integrations-table {\n  margin-top: 16px;\n}\n\n.integrations-actionsList {\n  display: flex;\n  flex-direction: column;\n  width: 200px;\n  border-radius: 2px;\n}\n\n.integrations-actionItem {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: 0;\n  white-space: nowrap;\n  border-left: 2px solid transparent;\n  cursor: pointer;\n  min-width: 84px;\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n\n  &:hover {\n    background: var(--cards-background);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newIntegrationButton": "Integrations-module__kBEpovVBYshJrLp8swu7",
	"heartbeat-badge": "Integrations-module__U5cWP5679LrHpx7BmAcY",
	"integrations-header": "Integrations-module__zKImn7vBrA0CIzrEvDi8",
	"integrations-table-row": "Integrations-module__eRLcsHEnQjq9OiGpfgtF",
	"integrations-table": "Integrations-module__Z4XoGpuAk0n3Xf4xqtOy",
	"integrations-actionsList": "Integrations-module__FjFvzvSG8py9_wYalVEn",
	"integrations-actionItem": "Integrations-module__Z90vOvIKuEGLk8qcenaJ"
};
export default ___CSS_LOADER_EXPORT___;
